import { Button, Form, Row, Select } from "antd";
import TagInput from "../basicInfo/TagInput";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TagInputForChild from "../basicInfo/TagInputForChild";

// const formItemLayout = {
//   labelCol: {
//     xs: { span: 24 },
//     sm: { span: 4 },
//   },
//   wrapperCol: {
//     xs: { span: 24 },
//     sm: { span: 20 },
//   },
// };
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const ReelsLinkingConfigurator = ({ form, name, connectedReelsName, availableLinkableReels }: any) => {
  // const wilds = ["sym1", "sym2"];
  console.log("Reels Linking Configurator", );
  return (
    <>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
          // debugger;
          // const oldVal = get(prevValues, name);
          // const newVal = get(curValues, name);
          // if (newVal && oldVal !== newVal) {
          //   return true;
          // }
          return true;
        }}
      >
        {() => {
          return (
            <>
              <Form.Item name={name} label="reelLinking">
                <Select defaultActiveFirstOption>
                  <Select.Option value={0}>None</Select.Option>
                  <Select.Option value="static">Static</Select.Option>
                  <Select.Option value="dynamic">Dynamic</Select.Option>
                  <Select.Option value="static_chuncked" disabled>
                    Static Chuncked
                  </Select.Option>
                  <Select.Option value="dynamic_chuncked" disabled>
                    Dynamic Chuncked
                  </Select.Option>
                </Select>
              </Form.Item>
              {form?.getFieldValue(name) === "static" && (
                <TagInput
                  name={connectedReelsName}
                  form={form}
                  label="connectedReels"
                  valueType="integer"
                  rules={[
                    { required: true, message: "Please input connectedReels!" },
                  ]}
                ></TagInput>
              )}
              {form?.getFieldValue(name) === "dynamic" && (
                <>
                  <Form.List
                    name={availableLinkableReels}
                    rules={
                      [
                        //   {
                        //     validator: async (_, names) => {
                        //       if (!names || names.length < 2) {
                        //         return Promise.reject(new Error("At least 2 passengers"));
                        //       }
                        //     },
                        //   },
                      ]
                    }
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row>
                            <TagInputForChild
                              label={`LinkableReels ${index}: `}
                              fieldPath={availableLinkableReels}
                              field={field}
                              form={form}
                              valueType="integer"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please input LinkableReels!",
                              //   },
                              // ]}
                            ></TagInputForChild>
                            {/* <Form.Item
                              name={field.name}
                              rules={[
                                {
                                  validator: (rule, value) => {
                                    return Promise.resolve();
                                    // console.log(form.getFieldValue("dynamicReelLinking"));
                                    // console.log(form.getFieldValue("clientId"));
                                    // return value.length > 2
                                    //   ? Promise.resolve()
                                    //   : Promise.reject(new Error("Should be a number"));
                                  },
                                },
                              ]}
                            >
                              <Select
                                mode="tags"
                                style={{ width: "80%" }}
                                placeholder="Please select"
                                defaultValue={[]}
                              ></Select>
                            </Form.Item> */}
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          </Row>
                        ))}
                        <Form.Item {...formItemLayoutWithOutLabel}>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                          >
                            Add Linkable reel
                          </Button>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </>
              )}
            </>
          );
        }}
      </Form.Item>
    </>
  );
};

export default ReelsLinkingConfigurator;
