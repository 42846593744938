// @ts-nocheck

import { Button, Collapse, Input, Select, Space, Table } from "antd";
import axios from "axios";
import React, { useState } from "react";

const { Panel } = Collapse;

const serverHost = "https://staging-game-play-service-xrfoa2ko5q-em.a.run.app";

interface IEnvironment {
  environment: string;
  spinType: string;
  addOnMultiplier: number;
}

interface IMoney {
  cash: number;
  coin: number;
  currency: string;
}
interface IAction {
  action: string;
  mode: string;
  reelset: string;
}
interface IRoundResponse {
  roundOver: boolean;
  reels: any[];
  balance: IMoney;
  clientAction: string;
  success: boolean;
  availableReelsets: any; // in init request
  next: IAction;
  current: IAction;

  symbolsSet: {
    outcome: string[][];
    dropIn?: string[][];
    hold?: string[][];
  };

  shouldPlaceBet: boolean;
  shouldUpdateWin: boolean;
  shouldSaveRound: boolean;
  roundRef: string;
  playIndex: number;
  subRoundRef: string;
}

interface IInitRoundResponse extends IRoundResponse {
  /* INIT RESPONSE */
  gameid?: string;
  gameLink?: string;
  server?: string;
  staticLink?: string;
  configid?: string;
  numberOfReels?: number;
  symbolsPerReel?: number;
  wincalculator?: string;
  numberOfBetLines?: number;
  bonusGame?: string;
  betLines?: Array<any>;
  availableSymbols: Array<string>;
  clientId: string; // todo can be cleaned
  name: string; // todo can be cleaned
  configurator: string; // todo can be cleaned
  operatorid: string;
  clubbedSymbols: Array<string>;
  wildBehaviour: string;
  status: string;
  defaultReelset: string;
  cheats: any;
  /* END INIT RESPONSE */
}

interface IResponsePayload {
  gameState: {
    now: IEnvironment;
    upcoming: IEnvironment;
  };
  result: {
    balance: IMoney;

    transactionProfile: any;
    roundId: string;
    walletRef: string;
  };
  rounds: Array<IRoundResponse | IInitRoundResponse>;
}

interface IResponse {
  success: true;
  type: "ejson";
  payload: IResponsePayload;
}

const TesterGame = () => {
  const [playerId, setPlayerId] = useState<string>("lbg-1693770916424");
  const [configId, setConfigId] = useState<string>("x7VHrKXCSmnhjLvqLQntJ8");
  const [gameId, setGameId] = useState<string>("slot-linked-server");
  const [operatorId, setOperatorId] = useState<string>(
    "5f8ae3cbc34272000af1f3bf"
  );
  const [fetching, setFetching] = useState<boolean>(false);
  const [initServerResponse, setInitServerResponse] = useState<IResponse>();
  const [serverResponse, setResponseResponse] = useState<IResponse>();
  const [activeSubRoundIndex, setActiveSubRoundIndex] = useState<number>(0);

  const [simulateCheats, setSimulateCheats] = useState<object>();
  const [highlights, setHighlights] = useState();
  const onGamePlay = async (
    action: IAction = {
      action: "init",
      mode: "basic",
      reelset: "basic",
    }
  ) => {
    setFetching(true);
    const sResponse = await axios.post(`${serverHost}/api/game/get-result`, {
      __config: {
        configid: configId,
        gameid: gameId,
        operatorid: operatorId,
        playerid: playerId,
        server: serverHost,
      },
      action,
      playData: {
        bet:
          action.action === "init"
            ? undefined
            : { cash: 27, coin: 270, currency: "DEMO" },
      },
      cheating: {
        name: simulateCheats?.name,
        positions: simulateCheats?.positions,
      },
    });
    if (sResponse && sResponse.data) {
      setResponseResponse(sResponse.data);
      setActiveSubRoundIndex(0); // TODO, may be different for bonus
      if (action.action === "init") {
        setInitServerResponse(sResponse.data);
      }
    } else {
      alert("Error While Fetching");
    }
    setFetching(false);
  };

  const handleSimulation = (selectedSimulation: any) => {
    const selectedCheat = initServerResponse.payload.rounds[0]?.cheats?.find(
      (cheats: any) => cheats.name === selectedSimulation
    );

    if (selectedCheat) {
      const makeArray = selectedCheat.positions.split(",").map(Number);
      setSimulateCheats({ ...selectedCheat, positions: makeArray });
    } else {
      setSimulateCheats(undefined);
    }
  };
  const activeSubRound = serverResponse?.payload?.rounds[activeSubRoundIndex];
  const remainingSubRounds =
    serverResponse?.payload?.rounds?.length - (activeSubRoundIndex + 1);
  const isLastSubRound = remainingSubRounds === 0;

  const bgColorAccordingIdx = (reelIdx, symIdx) => {
    let highlightColor = "#fff";
    debugger;
    for (
      let winSituationIdx = 0;
      winSituationIdx < highlights?.length;
      winSituationIdx++
    ) {
      const winSitn = highlights[winSituationIdx];

      if (winSitn.line[reelIdx] === symIdx) {
        highlightColor = winSitn.color || "#ccc";
      }
      const isSymOnPath = winSitn?.path?.find(
        (p) => p.reelIdx === reelIdx && p.symIdx === symIdx
      );
      if (isSymOnPath) {
        if (winSitn.viewType === "FRAME") {
          highlightColor = winSitn.color || "#ffe3e3";
        } else {
          highlightColor = winSitn.color || "#d0ffb7";
        }
      }
    }

    const isSymbolHasLayer = activeSubRound?.layers?.find(
      (layerSymbol) => layerSymbol.x === reelIdx && layerSymbol.y === symIdx
    );

    return (
      <td
        style={{
          backgroundColor: highlightColor,
        }}
      >
        <div>
          <span>
            {activeSubRound?.symbolsSet?.outcome[reelIdx][symIdx] || ""}
          </span>
          <span className="overlaySymbol">{isSymbolHasLayer?.sym}</span>
        </div>
      </td>
    );
  };

  const onPlaySubRound = () => {
    if (isLastSubRound) {
      onGamePlay(activeSubRound?.next);
    }
    setActiveSubRoundIndex(activeSubRoundIndex + 1);
  };

  const upComingEnvironment =
    serverResponse?.payload.gameState?.upcoming?.environment;
  const nowEnvironment = serverResponse?.payload.gameState?.now?.environment;
  const collections = serverResponse?.payload.gameState?.collections;
  console.log(activeSubRound);

  let simulations: any[] = [{ value: "- NO SELECT -", label: "- NO SELECT -" }];
  initServerResponse?.payload?.rounds[0].cheats.map((simulation: any) => {
    const singleSimulation = {
      value: simulation.name,
      label: simulation.name,
    };
    simulations.push(singleSimulation);
  });

  const stoppingPositions = activeSubRound?.state?.final
    ?.map((sp) => sp.ipos)
    .join(",");

  return (
    <div style={{ width: 600, margin: "0 auto", marginTop: "12px" }}>
      <div>
        <Input
          onChange={(val) => {
            setConfigId(val?.currentTarget?.value || "");
          }}
          value={configId}
        ></Input>
        <Button onClick={() => onGamePlay()}>Load Game</Button>
      </div>

      {initServerResponse && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{ width: "100%", fontSize: "16px", marginTop: "12px" }}
            >
              <div>
                Environment: {`${nowEnvironment} ---> ${upComingEnvironment}`}
              </div>
              {collections && (
                <div>Collections: {JSON.stringify(collections)}</div>
              )}
            </span>

            <span>
              <div>Postions:{stoppingPositions}</div>
              <Select
                defaultValue="Simulation"
                style={{
                  width: 160,
                }}
                onChange={handleSimulation}
                options={simulations}
              />
            </span>
          </div>
          <table className="reelset-preview">
            <tr>
              <td></td>
              {[
                ...Array(initServerResponse.payload.rounds[0].numberOfReels),
              ].map((e, reelIdx) => (
                <td style={{ background: "#c7e9ff" }}>rIdx: {reelIdx}</td>
              ))}
            </tr>
            {[
              ...Array(initServerResponse.payload.rounds[0].symbolsPerReel),
            ].map((e, symIdx) => (
              <tr key={symIdx}>
                <td style={{ background: "#c7e9ff" }}>sIdx:{symIdx}</td>
                {[...Array(5)].map((e, reelIdx) =>
                  bgColorAccordingIdx(reelIdx, symIdx)
                )}
              </tr>
            ))}
          </table>

          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ marginBottom: 0 }}>
                Cash: <span>{activeSubRound?.balance?.cash || "..."} </span>
              </p>
              <p style={{ marginBottom: 0 }}>
                Bet: <span>{activeSubRound?.balance?.coin || "..."}</span>
              </p>
              <p style={{ marginBottom: 0 }}>
                Win: <span>{activeSubRound?.win?.cash || "..."}</span>
              </p>
              <p style={{ marginBottom: 0 }}>
                Round Win:{" "}
                <span>{activeSubRound?.roundWin?.cash || "..."}</span>
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "16px 0",
            }}
          >
            <div>
              <Button>
                Mode:{" "}
                {`${activeSubRound?.current.mode || ""} (${
                  activeSubRound?.current.action || ""
                }) --> ${activeSubRound?.next.mode || ""}(${
                  activeSubRound?.next.action || ""
                })`}
              </Button>
            </div>
            <div>
              <Button
                onClick={onPlaySubRound}
                loading={fetching}
                disabled={fetching}
                type="primary"
              >
                {isLastSubRound || fetching
                  ? `Play`
                  : `Play Sub Round (Remaining: ${remainingSubRounds})`}
              </Button>
            </div>
          </div>
          <div style={{ marginTop: "12px", marginBottom: "12px" }}>
            <Space>
              {activeSubRound?.winSituations?.map((situation) => (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onMouseOver={() => setHighlights([situation])}
                    onMouseOut={() => setHighlights(null)}
                  >
                    Line {situation.number}
                  </button>
                </>
              ))}
            </Space>
          </div>
        </>
      )}

      {initServerResponse && (
        <div>
          <Collapse>
            <Panel header="Lines definitions" key="1">
              <Space>
                {initServerResponse.payload.rounds[0].betLines.map((btn, i) => (
                  <>
                    <button
                      onMouseOver={() => setHighlights([btn])}
                      onMouseOut={() => setHighlights(null)}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Line {btn.idx}
                    </button>
                  </>
                ))}
              </Space>
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default TesterGame;
