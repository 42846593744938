import AWS from "aws-sdk";
import {
  ChonkyActions,
  ChonkyFileActionData,
  FileArray,
  FileBrowser,
  FileData,
  FileList,
  FileNavbar,
  FileToolbar,
  setChonkyDefaults,
  FileContextMenu,
} from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import path from "path";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "../hooks/useQuery";
import { useDropzone } from "react-dropzone";
import { Modal, Image, Button } from "antd";
import Editor from "@monaco-editor/react";

var mime = require("mime-types");
const fileTypesAllowedForUser = [
  "json",
  "atlas",
  "png",
  "jpg",
  "jpeg",
  "fnt",
  "woff2",
  "zip",
];
const thumbnailSupportedExtensions = ["png", "jpg", "jpeg"];
const editorSupportedExtensions = ["json", "atlas", "js", "html", "fnt"];
setChonkyDefaults({ iconComponent: ChonkyIconFA });

const myFileActions = [
  ChonkyActions.UploadFiles,
  // ChonkyActions.DownloadFiles,
  // ChonkyActions.DeleteFiles,
];

// The AWS credentials below only have read-only access to the Chonky demo bucket.
// You will need to create custom credentials for your bucket.
const BUCKET_NAME = "storage-for-tutors";
// const BUCKET_REGION = "eu-west-2";
const ACCESS_KEY_ID = "DO004Z6Y83XC78JDPMW9";
const SECRET_ACCESS_KEY = "FPZIB/N+cRruYttdRIBfIMdpG4rupTgcq73z/HmYAA8";
const URL = "https://storage-for-tutors.ams3.digitaloceanspaces.com";

// AWS.config.update({
// //   region: BUCKET_REGION,
//   signatureVersion: 'v4',
//   s3BucketEndpoint: URL,
//   accessKeyId: ACCESS_KEY_ID,
//   secretAccessKey: SECRET_ACCESS_KEY,
// });
const s3 = new AWS.S3({
  endpoint: URL,
  s3BucketEndpoint: true,
  //s3ForcePathStyle: acc.pathStyle,
  credentials: new AWS.Credentials(ACCESS_KEY_ID, SECRET_ACCESS_KEY),
  signatureVersion: "v4",
});
const getExtension = (filePath: string) => filePath.split(".").pop() || "";

const getThumbnailUrl = (filePath: string) => {
  const extension: string = getExtension(filePath);
  if (thumbnailSupportedExtensions.includes(extension)) {
    return `${URL}/${filePath}`;
  }
  return undefined;
};
const fetchS3BucketContents = (
  bucket: string,
  prefix: string = "game"
): Promise<FileArray> => {
  return s3
    .listObjectsV2({
      Bucket: bucket,
      Delimiter: "/",
      Prefix: prefix !== "/" ? prefix : "",
    })
    .promise()
    .then((response: any) => {
      const chonkyFiles: FileArray = [];
      const s3Objects = response.Contents;
      const s3Prefixes = response.CommonPrefixes;

      if (s3Objects) {
        chonkyFiles.push(
          ...s3Objects
            .filter((obj: any) => {
              var fileExt = obj.Key?.split(".").pop();
              return fileTypesAllowedForUser.includes(fileExt);
            })
            .map(
              (object: any): FileData => ({
                id: object.Key!,
                name: path.basename(object.Key!),
                modDate: object.LastModified,
                size: object.Size,
                thumbnailUrl: getThumbnailUrl(object.Key),
              })
            )
        );
      }

      if (s3Prefixes) {
        chonkyFiles.push(
          ...s3Prefixes.map(
            (prefix: any): FileData => ({
              id: prefix.Prefix!,
              name: path.basename(prefix.Prefix!),
              isDir: true,
            })
          )
        );
      }

      return chonkyFiles;
    });
};

const ClientExplorer: React.FC = () => {
  let query = useQuery();
  const initPath = query.get("path");
  const [error, setError] = useState<string | null>(null);
  const [folderPrefix, setKeyPrefix] = useState<string>("");
  const [files, setFiles] = useState<FileArray>([]);

  const [isAssetPreviewModalOpen, setIsAssetPreviewModalOpen] = useState(false);
  const [assetPreviewPath, setAssetPreviewPath] = useState("");

  const [isCodeEditorModalOpen, setIsCodeEditorModalOpen] = useState(false);
  const [fileToEdit, setFileToEdit] = useState("");
  const [extensionOfFileToEdit, setExtensionOfFileToEdit] = useState("");
  const [editingFileContent, setEditingFileContent] = useState<any>();
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [imagePreviewVisible, setImagePreviewVisible] = useState(false);

  const showCodeEditorModal = (filePath: string) => {
    setFileToEdit(filePath);
    if (filePath.indexOf(".html") > 0) {
      setExtensionOfFileToEdit("html");
      // this.editorOptions.contentType = 'text/html'
    }
    if (filePath.indexOf(".atlas") > 0) {
      setExtensionOfFileToEdit("text");
      // this.editorOptions.contentType = 'application/octet-stream'
    }
    if (filePath.indexOf(".json") > 0) {
      setExtensionOfFileToEdit("json");
      // this.editorOptions.contentType = 'application/octet-stream'
    }
    var getParams = {
      Bucket: BUCKET_NAME, // your bucket name,
      Key: filePath, // path to the object you're looking for
    };
    s3.getObject(getParams)
      .promise()
      .then((data: any) => {
        let objectData = data.Body.toString("utf-8");
        setEditingFileContent(JSON.parse(JSON.stringify(objectData)));
        setIsCodeEditorModalOpen(true);
      })
      .catch(() => {
        window.alert("OOPS! Something went wrong");
      });
  };

  const handleOkFromCodeEditorModal = () => {
    setIsCodeEditorModalOpen(false);
    saveCodeEditorValue();
  };

  const handleCancelFromCodeEditor = () => {
    setIsCodeEditorModalOpen(false);
    setFileToEdit("");
    setExtensionOfFileToEdit("");
    setEditingFileContent(undefined);
  };
  const isJsonIsPreviewableInAnimator = () => {
    let type;
    if (!editingFileContent) {
      return;
    }
    if (
      (typeof editingFileContent === "string" &&
        editingFileContent?.indexOf("texturepacker") > 0) ||
      editingFileContent?.meta?.app?.indexOf("texturepacker") > 0
    ) {
      type = "texturepacker";
    } else if (
      (typeof editingFileContent === "string" &&
        (editingFileContent?.indexOf("skeleton") > 0 ||
          editingFileContent?.indexOf("bones") > 0)) ||
      editingFileContent.skeleton ||
      editingFileContent.bones
    ) {
      type = "spine";
    }
    return type;
  };
  const handleShowAssetPreviewModal = (filePath: string) => {
    if (filePath.indexOf(".json") > 0) {
      // no need to fetch the content again as as it is already there in the editor dialog
      const type = isJsonIsPreviewableInAnimator();
      if (type) {
        setAssetPreviewPath(`pixi/?type=${type}&path=${URL}/${fileToEdit}`);
        setIsAssetPreviewModalOpen(true);
      }
    }
  };
  const handleOkFromAssetPreview = () => {
    setIsAssetPreviewModalOpen(false);
    setAssetPreviewPath("");
  };

  const handleCancelFromAssetPreview = () => {
    setIsAssetPreviewModalOpen(false);
    setAssetPreviewPath("");
  };

  useEffect(() => {
    setKeyPrefix(initPath || "");
  }, [initPath]);

  useEffect(() => {
    if (!folderPrefix || folderPrefix.length < 2) {
      return;
    }
    fetchS3BucketContents(BUCKET_NAME, folderPrefix)
      .then(setFiles)
      .catch((error) => setError(error.message));
  }, [folderPrefix, setFiles]);

  const folderChain = React.useMemo(() => {
    let folderChain: FileArray;
    if (folderPrefix === "/" || folderPrefix === "") {
      folderChain = [];
    } else {
      let currentPrefix = "";
      folderChain = folderPrefix
        .replace(/\/*$/, "")
        .split("/")
        .map((prefixPart): FileData => {
          currentPrefix = currentPrefix
            ? path.join(currentPrefix, prefixPart)
            : prefixPart;
          return {
            id: currentPrefix,
            name: prefixPart,
            isDir: true,
          };
        });
    }
    folderChain.unshift({
      id: "/",
      name: BUCKET_NAME,
      isDir: true,
    });
    return folderChain;
  }, [folderPrefix]);

  const uploadFilesToBrowsedLocation = useCallback(
    (files: Array<any>) => {
      const uploadingQueue = [];
      for (let idx = 0; idx < files.length; idx++) {
        const file = files[idx];
        const filePath = `${folderPrefix}${file.path || file.name}`.replaceAll(
          "//",
          "/"
        );
        console.log(`Uploading: ${filePath}`);
        const params = {
          Body: file,
          Bucket: BUCKET_NAME,
          Key: filePath,
          ACL: "public-read",
          ContentType: mime.lookup(file.name) || "text/plain",
        };
        uploadingQueue.push(s3.upload(params).promise());
        // s3.upload(params)
        //   .promise()
        //   .then(
        //     (da: any) => {
        //       debugger;
        //       console.log(da);
        //     },
        //     (err: any) => {
        //       debugger;
        //       console.log(err);
        //     }
        //   );
      }
      Promise.all(uploadingQueue).then(() => {
        fetchS3BucketContents(BUCKET_NAME, folderPrefix)
          .then(setFiles)
          .catch((error) => setError(error.message));
      });
    },
    [folderPrefix]
  );

  const deleteFilesFromAws = useCallback(
    (locationsToDelete: FileData[]) => {
      const uploadingQueue = [];
      for (let idx = 0; idx < locationsToDelete.length; idx++) {
        const params = {
          Bucket: BUCKET_NAME,
          Key: locationsToDelete[idx].id,
        };
        uploadingQueue.push(s3.deleteObject(params).promise());
      }
      Promise.all(uploadingQueue).then(() => {
        fetchS3BucketContents(BUCKET_NAME, folderPrefix)
          .then(setFiles)
          .catch((error) => setError(error.message));
      });
    },
    [folderPrefix]
  );

  const handleFileAction = useCallback(
    (data: ChonkyFileActionData) => {
      if (data.id === ChonkyActions.DeleteFiles.id) {
        const locationsToDelete = data.state.selectedFilesForAction;
        if (locationsToDelete?.length > 0) {
          if (
            window.confirm("Are you sure you want to delete selected files?")
          ) {
            deleteFilesFromAws(locationsToDelete);
          }
        }
      }
      if (data.id === ChonkyActions.UploadFiles.id) {
        var input = document.createElement("input");
        input.type = "file";
        input.name = "file";
        input.multiple = true;

        input.onchange = (e) => {
          // @ts-ignore
          const files = e.target.files;
          uploadFilesToBrowsedLocation(files);
        };
        input.click();
      }
      if (data.id === ChonkyActions.OpenFiles.id) {
        if (data.payload.files && data.payload.files.length !== 1) return;
        if (
          data.payload.files &&
          data.payload.files.length === 1 &&
          !data.payload.files[0].isDir
        ) {
          console.log("OPEN FILE");
          const filePath = data.payload.files[0].id;
          const extension: string = getExtension(filePath);
          if (editorSupportedExtensions.includes(extension)) {
            showCodeEditorModal(filePath);
          }
          if (thumbnailSupportedExtensions.includes(extension)) {
            const image = getThumbnailUrl(filePath);
            if (image) {
              setPreviewImageUrl(image);
              setImagePreviewVisible(true);
            }
          }
          return;
        }
        if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;

        const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, "")}/`;
        setKeyPrefix(newPrefix);
      }
    },
    [deleteFilesFromAws, uploadFilesToBrowsedLocation]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      uploadFilesToBrowsedLocation(acceptedFiles);
    },
    [uploadFilesToBrowsedLocation]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const editorRef = useRef(null);

  const handleEditorDidMount = useCallback((editor: any, monaco: any) => {
    editorRef.current = editor;
  }, []);

  const saveCodeEditorValue = useCallback(() => {
    // @ts-ignore
    const updatedCode = editorRef?.current?.getValue();
    const params = {
      Bucket: BUCKET_NAME,
      Key: fileToEdit,
      Body: updatedCode,
      ContentType: mime.lookup(fileToEdit),
      ACL: "public-read",
    };
    s3.putObject(params)
      .promise()
      .then(() => {
        setFileToEdit("");
        setExtensionOfFileToEdit("");
        setEditingFileContent(undefined);

        fetchS3BucketContents(BUCKET_NAME, folderPrefix)
          .then(setFiles)
          .catch((error) => setError(error.message));
      });
  }, [fileToEdit, folderPrefix]);

  return (
    <>
      <div style={{ margin: "10px" }}>
        <div>
          {error && (
            <div>
              An error has occurred while loading bucket:{" "}
              <strong>{error}</strong>
            </div>
          )}
        </div>
        <div style={{ height: 400 }}>
          <FileBrowser
            // darkMode={true}
            //   instanceId={storyName}
            files={files}
            folderChain={folderChain}
            onFileAction={handleFileAction}
            fileActions={myFileActions}
          >
            <FileNavbar />
            <FileToolbar />
            <FileList />
            <FileContextMenu />
            <div
              {...getRootProps()}
              style={{
                background: "#ccc",
                border: "1px dashed black",
                borderRadius: "4px",
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
          </FileBrowser>
        </div>
      </div>

      <Modal
        style={{ top: 20 }}
        bodyStyle={{ padding: 0 }}
        title={fileToEdit}
        open={isCodeEditorModalOpen}
        onOk={handleOkFromCodeEditorModal}
        onCancel={handleCancelFromCodeEditor}
        width={"80%"}
        okText={"Save"}
      >
        {!!isJsonIsPreviewableInAnimator() && (
          <div style={{ textAlign: "right", marginRight: "20px" }}>
            <Button
              onClick={() => handleShowAssetPreviewModal(fileToEdit)}
              type="primary"
            >
              Preview
            </Button>
          </div>
        )}
        {isCodeEditorModalOpen && (
          <Editor
            onMount={handleEditorDidMount}
            height="70vh"
            defaultLanguage={"json"}
            language={extensionOfFileToEdit}
            defaultValue="{}"
            value={editingFileContent}
          />
        )}
      </Modal>
      <Modal
        style={{ top: 0 }}
        title="Preview"
        open={isAssetPreviewModalOpen}
        onOk={handleOkFromAssetPreview}
        onCancel={handleCancelFromAssetPreview}
        width={"100%"}
        bodyStyle={{ height: "80vh", padding: 0 }}
        footer={null}
      >
        <iframe
          title="Preview"
          width="100%"
          height="100%"
          src={assetPreviewPath}
        ></iframe>
      </Modal>
      <Image
        width={200}
        style={{ display: "none" }}
        src={previewImageUrl}
        preview={{
          visible: imagePreviewVisible,
          scaleStep: 0.5,
          src: previewImageUrl,
          onVisibleChange: (value) => {
            setImagePreviewVisible(value);
            setPreviewImageUrl("");
          },
        }}
      />
    </>
  );
};

export default ClientExplorer;
