import { Form, Select } from "antd";
import TagInput from "../basicInfo/TagInput";
import get from "lodash/get";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
// const formItemLayoutWithOutLabel = {
//   wrapperCol: {
//     xs: { span: 24, offset: 0 },
//     sm: { span: 20, offset: 4 },
//   },
// };

const SymbolBehaviourConfigurator = ({ form, name, configurationName }: any) => {
  // const wilds = ["sym1", "sym2"];
  console.log("WildConfigurator");

  return (
    <>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
          const oldVal = get(prevValues, name);
          const newVal = get(curValues, name);
          if (newVal && oldVal !== newVal) {
            return true;
          }
          return false;
        }}
      >
        {() => {
          return (
            <>
              <TagInput
                name={name}
                form={form}
                label="wildsymbols"
                // rules={[{ required: true, message: "Please input symbols!" }]}
                help={"Subset of symbols"}
                onUpdate={() => {
                  console.log(form?.getFieldValue(name));
                }}
              ></TagInput>
              <Form.Item
                {...formItemLayout}
                // label={`Config`}
                required={false}
                key={"wildb"}
                name={configurationName}
              >
                {form?.getFieldValue(name)?.map((wildSym: string) => (
                  <Form.Item
                    {...formItemLayout}
                    label={`: ${wildSym}`}
                    required={false}
                    key={wildSym}
                  >
                    <Form.Item
                      name={[...configurationName, wildSym, "behaviour"]}
                      // key={[field.key, "number"]}
                      noStyle
                    >
                      <Select>
                        <Select.Option value="">None</Select.Option>
                        <Select.Option value="trigger-respin">
                          Trigger Respin
                        </Select.Option>
                        <Select.Option value="random-trigger-respin" disabled>
                          Random Wild and Respin
                        </Select.Option>
                        <Select.Option value="stick-and-trigger-respin">
                          Stick and Trigger Respin
                        </Select.Option>
                        <Select.Option value="walk-left-and-trigger-respin">
                          Walk to left and Trigger Respin
                        </Select.Option>
                        <Select.Option value="expand-trigger-respin">
                          Expand Wild and Respin
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Form.Item>
                ))}
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </>
  );
};

export default SymbolBehaviourConfigurator;
