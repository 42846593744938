// sym0,sym1,sym8,sym3,sym4,sym5,sym6,sym3,sym8,sym9,sym10,sym11
// sym1,sym8,sym3
const colossal = {
  betLines: [
    {
      idx: 0,
      line: [1, 1, 1, 1, 1],
      number: 1,
    },
    {
      idx: 1,
      line: [0, 0, 0, 0, 0],
      number: 2,
    },
    {
      idx: 2,
      line: [2, 2, 2, 2, 2],
      number: 3,
    },
    {
      idx: 3,
      line: [0, 1, 2, 1, 0],
      number: 4,
    },
    {
      idx: 4,
      line: [2, 1, 0, 1, 2],
      number: 5,
    },
    {
      idx: 5,
      line: [0, 0, 1, 0, 0],
      number: 6,
    },
    {
      idx: 6,
      line: [2, 2, 1, 2, 2],
      number: 7,
    },
    {
      idx: 7,
      line: [1, 2, 2, 2, 1],
      number: 8,
    },
    {
      idx: 8,
      line: [1, 0, 0, 0, 1],
      number: 9,
    },
  ],
  numberOfBetLines: 9,
  numberOfReels: 5,
  reelsetMapping: {
    basic: ["basic"],
    respin: ["respin"],
    freespin: ["freespin"],
  },
  defaultReelset: "basic",
  availableReelsets: {
    basic: {
      fakereels: [
        [
          "sym0",
          "sym1",
          "sym8",
          "sym3",
          "sym4",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym10",
          "sym11",
        ],
        [
          "sym0",
          "sym1",
          "sym8",
          "sym3",
          "sym4",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym10",
          "sym11",
        ],
        [
          "sym0",
          "sym1",
          "sym8",
          "sym3",
          "sym4",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym10",
          "sym11",
        ],
        [
          "sym0",
          "sym1",
          "sym8",
          "sym3",
          "sym4",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym10",
          "sym11",
        ],
        [
          "sym0",
          "sym1",
          "sym8",
          "sym3",
          "sym4",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym10",
          "sym11",
          "sym1",
          "sym8",
        ],
      ],
      initial: [
        ["sym1", "sym8", "sym3"],
        ["sym5", "sym6", "sym3"],
        ["sym9", "sym10", "sym11"],
        ["sym8", "sym3_1x2_1-1", "sym3_1x2_1-2"],
        ["sym10", "sym5", "sym4"],
      ],
      paytable: [
        {
          payout: [
            {
              freespins: 3,
              multiplier: 0,
              number: 3,
              type: "scatter",
            },
            {
              freespins: 5,
              multiplier: 0,
              number: 4,
              type: "scatter",
            },
            {
              freespins: 8,
              multiplier: 0,
              number: 5,
              type: "scatter",
            },
          ],
          sym: "sym0",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 5,
              type: "",
            },
          ],
          sym: "sym1",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym2",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym3",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym4",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym5",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym6",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym7",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym8",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym9",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym10",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 2,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 3,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 5,
              number: 5,
              type: "",
            },
          ],
          sym: "sym11",
        },
      ],
      reels: [
        [
          "sym0",
          "sym1",
          "sym8",
          "sym3",
          "sym4",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym10",
          "sym11",
          "sym3_1x2_1-1",
          "sym3_1x2_1-2",
        ],
        [
          "sym0",
          "sym1",
          "sym8",
          "sym3",
          "sym4",
          "sym3_1x2_1-1",
          "sym3_1x2_1-2",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym10",
          "sym11",
        ],
        [
          "sym0",
          "sym1",
          "sym3_1x2_1-1",
          "sym3_1x2_1-2",
          "sym8",
          "sym3",
          "sym4",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym10",
          "sym11",
        ],
        [
          "sym0",
          "sym1",
          "sym8",
          "sym3",
          "sym4",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym3_1x2_1-1",
          "sym3_1x2_1-2",
          "sym10",
          "sym11",
        ],
        [
          "sym0",
          "sym1",
          "sym8",
          "sym3",
          "sym4",
          "sym5",
          "sym6",
          "sym3",
          "sym8",
          "sym9",
          "sym3_1x2_1-1",
          "sym3_1x2_1-2",
          "sym10",
          "sym11",
          "sym8",
          "sym3",
          "sym5",
          "sym6",
          "sym3",
        ],
      ],
    },
    freespin: {
      fakereels: [
        ["sym8", "sym8", "sym3", "sym5", "sym3", "sym8"],
        ["sym3", "sym3", "sym8", "sym3", "sym8", "sym3"],
        ["sym4", "sym8", "sym9", "sym3", "sym8"],
        ["sym5", "sym9", "sym8", "sym3", "sym8", "sym3"],
        ["sym6", "sym3", "sym9", "sym3", "sym8", "sym3"],
      ],
      initial: [
        ["sym5", "sym9", "sym8", "sym3"],
        ["sym8", "sym3", "sym10", "sym4"],
        ["sym4", "sym9", "sym8", "sym9"],
        ["sym3", "sym6", "sym8", "sym9"],
        ["sym5", "sym5", "sym5", "sym5"],
      ],
      paytable: [
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "scatter",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "scatter",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "scatter",
            },
          ],
          sym: "sym0",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym1",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym2",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym3",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym4",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym5",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym6",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym7",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym8",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym9",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym10",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym11",
        },
      ],
      reels: [
        ["sym9", "sym8", "sym3", "sym5", "sym3", "sym9", "sym3", "sym6"],
        ["sym3", "sym3", "sym8", "sym5", "sym3", "sym9"],
        ["sym4", "sym8", "sym9", "sym5", "sym3", "sym9", "sym3", "sym4"],
        ["sym5", "sym9", "sym8", "sym5", "sym3", "sym9", "sym3"],
        ["sym6", "sym3", "sym9", "sym5", "sym3", "sym9", "sym3", "sym4"],
      ],
      paytableObj: {
        sym0: [
          {
            freespins: 3,
            multiplier: 0,
            number: 3,
            type: "scatter",
          },
          {
            freespins: 5,
            multiplier: 0,
            number: 4,
            type: "scatter",
          },
          {
            freespins: 8,
            multiplier: 0,
            number: 5,
            type: "scatter",
          },
        ],
        sym1: [
          {
            freespins: 0,
            multiplier: 0,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 0,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 0,
            number: 5,
            type: "",
          },
        ],
        sym2: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym3: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym4: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym5: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym6: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym7: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym8: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym9: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym10: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym11: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
      },
    },
    respin: {
      fakereels: [
        ["sym9", "sym8", "sym3", "sym5", "sym3", "sym9"],
        ["sym3", "sym3", "sym8", "sym3", "sym9", "sym3"],
        ["sym4", "sym8", "sym9", "sym3", "sym9"],
        ["sym5", "sym9", "sym8", "sym3", "sym9", "sym3"],
        ["sym6", "sym3", "sym9", "sym3", "sym9", "sym3"],
      ],
      initial: [
        ["sym5", "sym9", "sym8", "sym9"],
        ["sym9", "sym3", "sym3", "sym4"],
        ["sym4", "sym9", "sym8", "sym9"],
        ["sym3", "sym6", "sym8", "sym9"],
        ["sym5", "sym5", "sym5", "sym5"],
      ],
      paytable: [
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "scatter",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "scatter",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "scatter",
            },
          ],
          sym: "sym0",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym1",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym2",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym3",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym4",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym5",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym6",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym7",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym8",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym9",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym10",
        },
        {
          payout: [
            {
              freespins: 0,
              multiplier: 0,
              number: 3,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 0,
              number: 4,
              type: "",
            },
            {
              freespins: 0,
              multiplier: 1,
              number: 5,
              type: "",
            },
          ],
          sym: "sym11",
        },
      ],
      reels: [
        ["sym8", "sym8", "sym3", "sym3", "sym8", "sym3"],
        ["sym3", "sym3", "sym8", "sym3", "sym8", "sym3", "sym4"],
        ["sym4", "sym8", "sym9", "sym3", "sym6"],
        ["sym5", "sym9", "sym8", "sym3", "sym4", "sym5", "sym6"],
        ["sym6", "sym3", "sym9", "sym3", "sym8", "sym5", "sym6"],
      ],
      paytableObj: {
        sym0: [
          {
            freespins: 3,
            multiplier: 0,
            number: 3,
            type: "scatter",
          },
          {
            freespins: 5,
            multiplier: 0,
            number: 4,
            type: "scatter",
          },
          {
            freespins: 8,
            multiplier: 0,
            number: 5,
            type: "scatter",
          },
        ],
        sym1: [
          {
            freespins: 0,
            multiplier: 0,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 0,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 0,
            number: 5,
            type: "",
          },
        ],
        sym2: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym3: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym4: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym5: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym6: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym7: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym8: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym9: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym10: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
        sym11: [
          {
            freespins: 0,
            multiplier: 2,
            number: 3,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 3,
            number: 4,
            type: "",
          },
          {
            freespins: 0,
            multiplier: 5,
            number: 5,
            type: "",
          },
        ],
      },
    },
  },
  paytableObj: {
    sym0: [
      {
        freespins: 2,
        multiplier: 0,
        number: 12,
        type: "scatter",
      },
      {
        freespins: 3,
        multiplier: 0,
        number: 13,
        type: "scatter",
      },
      {
        freespins: 5,
        multiplier: 0,
        number: 14,
        type: "scatter",
      },
      {
        freespins: 8,
        multiplier: 0,
        number: 15,
        type: "scatter",
      },
    ],
    sym1: [
      {
        freespins: 0,
        multiplier: 0,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 0,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 0,
        number: 5,
        type: "",
      },
    ],
    sym2: [
      {
        freespins: 0,
        multiplier: 20,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 30,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 50,
        number: 5,
        type: "",
      },
    ],
    sym3: [
      {
        freespins: 0,
        multiplier: 20,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 30,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 50,
        number: 5,
        type: "",
      },
    ],
    sym4: [
      {
        freespins: 0,
        multiplier: 20,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 30,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 50,
        number: 5,
        type: "",
      },
    ],
    sym5: [
      {
        freespins: 0,
        multiplier: 2,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 3,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 5,
        number: 5,
        type: "",
      },
    ],
    sym6: [
      {
        freespins: 0,
        multiplier: 2,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 3,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 5,
        number: 5,
        type: "",
      },
    ],
    sym7: [
      {
        freespins: 0,
        multiplier: 2,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 3,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 5,
        number: 5,
        type: "",
      },
    ],
    sym8: [
      {
        freespins: 0,
        multiplier: 2,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 3,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 5,
        number: 5,
        type: "",
      },
    ],
    sym9: [
      {
        freespins: 0,
        multiplier: 2,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 3,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 5,
        number: 5,
        type: "",
      },
    ],
    sym10: [
      {
        freespins: 0,
        multiplier: 2,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 3,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 5,
        number: 5,
        type: "",
      },
    ],
    sym11: [
      {
        freespins: 0,
        multiplier: 2,
        number: 3,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 3,
        number: 4,
        type: "",
      },
      {
        freespins: 0,
        multiplier: 5,
        number: 5,
        type: "",
      },
    ],
  },
  gameModes: ["basic", "respin", "freespin"],
  reelsets: {},
  scattersymbols: ["sym0"],
  specialfeature: "",
  symbols: [
    "sym0",
    "sym1",
    "sym3",
    "sym4",
    "sym5",
    "sym6",
    "sym8",
    "sym9",
    "sym10",
    "sym11",
  ],
  symbolsPerReel: 3,
  wildsymbols: ["sym1"],
  wincalculator: "betlines",
  gameid: "slot-linked-server",
  clientId: "goldcolossals",
  name: "Gold Colossals Basic Game",
  remark: "5x3 slot machine",
};

export const sampleConfigs = {
  colossal: colossal,
};
