export const Constants = {
  ELANTRA_AUTH_ME: "https://elantra-api.gameolive.com/api/auth/me",
};

export const GAME_SERVER_CONFIG = {
  server: "https://stage-2-gameolive-gs-jggub5n6qq-em.a.run.app",
  clientId:
    "SA_Game-815dae14-55ba-446e-bcf0-b97b1fec056e@e74c6b04-0558-417c-9a8f-c08b5a42b9a6.gol",
  clientSecret: "abc1602832965454",
  operatorId: "5f8ae3cbc34272000af1f3bf",
};

export const SIMULATION_GAME_SERVER_CONFIG = {
  server: "https://game-simulator-7a3vosavtq-em.a.run.app",
  clientId:
    "SA_Game-815dae14-55ba-446e-bcf0-b97b1fec056e@e74c6b04-0558-417c-9a8f-c08b5a42b9a6.gol",
  clientSecret: "abc1602832965454",
  operatorId: "631e1ad2fb9c35000acf73d7",
};
