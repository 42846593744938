import { Checkbox, Form, Input, InputNumber, Select } from "antd";
import TagInputForChildWithName from "../basicInfo/TagInputForChildWithName";

const CollectibleConfigurationPanel = ({ name, form, label }: any) => {
  return (
    <div style={{ background: "#ccc" }}>
      <Form.Item
        label="hasCollectibles"
        name={"hasCollectibles"}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      {/* <Form.Item name="collectibles" label="collectibles">
        <FieldInputJson />;
      </Form.Item> */}
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
          // debugger;
          // const oldVal = get(prevValues, name);
          // const newVal = get(curValues, name);
          // if (newVal && oldVal !== newVal) {
          //   return true;
          // }
          return true;
        }}
      >
        {() => {
          return (
            <>
              {form?.getFieldValue("hasCollectibles") && (
                <>
                  <Form.List
                    name={name}
                    rules={[
                      {
                        validator: async (_, names) => {
                          // if (!names || names.length < 2) {
                          //   return Promise.reject(new Error("At least 2 passengers"));
                          // }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <>
                            <TagInputForChildWithName
                              label={`Any of symbols : `}
                              fieldPath={["collectibles"]}
                              name="symbols"
                              field={field}
                              form={form}
                              subName="symbols"
                              //   symbolsSuggestions={[]}
                            />

                            <Form.Item name={[field.name, "name"]} label="Name">
                              <Input
                                placeholder="Name"
                                style={{ width: "60%" }}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "target"]}
                              label="Target"
                            >
                              <InputNumber
                                placeholder="Target"
                                style={{ width: "60%" }}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "trigger"]}
                              label="Collection will Trigger"
                            >
                              <Select>
                                <Select.Option value="gameEnvironmentChange">
                                  Change Game Environment
                                </Select.Option>
                                {/* <Select.Option value="triggerfeature">
                      Trigger Feature
                    </Select.Option>
                    <Select.Option value="triggerbonus">
                      Trigger Bonus
                    </Select.Option> */}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "result"]}
                              label="Result Game Environment"
                            >
                              <Input
                                placeholder="Result Game Environment"
                                style={{ width: "60%" }}
                              />
                            </Form.Item>
                            <hr />
                          </>
                        ))}
                      </>
                    )}
                  </Form.List>
                  {/* 
      {JSON.stringify(form.getFieldValue("collectibles"), null, "\t")} */}
                </>
              )}
            </>
          );
        }}
      </Form.Item>
    </div>
  );
};
export default CollectibleConfigurationPanel;
