import { Checkbox, Modal } from "antd";
import { useState } from "react";

const PreviewModal = ({ data, isVisible, onCancel }: any) => {
  const [showSymbols, setShowSymbols] = useState(false);
  const maxRows = Math.max(...data?.map((a: Array<String>) => a.length)) || 0;
  const cols = data?.length || 0;

  const handleSymbol = (e: any) => {
    setShowSymbols(e.target.checked);
  };

  // sym1_1x3_1-1
  const getSymbolConfig = (sym: string) => {
    if (!sym || sym === "") {
      return {
        name: "",
        isOrigin: true,
        colSpan: 1,
        rowSpan: 1,
        colPosition: 1,
        rowPosition: 1,
      };
    }
    if (sym.indexOf("_") <= 0) {
      return {
        name: sym,
        isOrigin: true,
        colSpan: 1,
        rowSpan: 1,
        colPosition: 1,
        rowPosition: 1,
      };
    }
    const symParts = sym.split("_");
    const name = symParts[0];

    const symDimensions = symParts[1].split("x");
    const colSpan = parseInt(symDimensions[0]);
    const rowSpan = parseInt(symDimensions[1]);

    const symPartPositions = symParts[2].split("-");
    const colPosition = parseInt(symPartPositions[0]);
    const rowPosition = parseInt(symPartPositions[1]);

    const isOrigin = colPosition === 1 && rowPosition === 1;
    return {
      name,
      isOrigin,
      colSpan,
      rowSpan,
      colPosition,
      rowPosition,
      nameWithoutPosition: `${symParts[0]}_${symParts[1]}`,
    };
  };
  return (
    <Modal
      title="Preview"
      visible={isVisible}
      footer={null}
      onCancel={onCancel}
      width={cols * 120}
    >
      <Checkbox onChange={handleSymbol}>Show Symbols</Checkbox>
      {showSymbols ? (
        <>
          <table className="reelset-preview">
            <tbody style={{ width: 554, overflow: "auto", display: "block" }}>
              {[...Array(maxRows)].map((e, rowIdx) => (
                <tr key={rowIdx}>
                  <td>{rowIdx}</td>
                  {[...Array(cols)].map((e, colIdx) =>
                    getSymbolCell(colIdx, rowIdx)
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <>
          <table className="reelset-preview">
            {[...Array(maxRows)].map((e, rowIdx) => (
              <tr key={rowIdx}>
                <td>{rowIdx}</td>
                {[...Array(cols)].map((e, colIdx) => (
                  <td>{data[colIdx][rowIdx]}</td>
                ))}
              </tr>
            ))}
          </table>
        </>
      )}
    </Modal>
  );

  function getSymbolCell(
    colIdx: number,
    rowIdx: number
  ): JSX.Element | undefined {
    const symbolToRender = data[colIdx][rowIdx];
    // const symbol =
    const symbolConfig = getSymbolConfig(symbolToRender);
    const symbol = symbolConfig.name;
    if (!symbolConfig.isOrigin) {
      return undefined;
    }
    return (
      <td
        style={{ textAlign: "center" }}
        colSpan={symbolConfig.colSpan}
        rowSpan={symbolConfig.rowSpan}
      >
        {symbol && symbol.length > 0 && (
          <>
            <div>
              <img
                src={`https://storage-for-tutors.ams3.digitaloceanspaces.com/game/snakesandladders/assets/snakesandladders/images/symbols/${symbolConfig.name}.png`}
                alt="symbol"
                style={{ width: 28 }}
              />
            </div>
            <div>{symbolConfig.nameWithoutPosition || symbolToRender}</div>
            {/* <div>{symbolConfig?.isOrigin && "origin"}</div> */}
          </>
        )}
      </td>
    );
  }
};
export default PreviewModal;
