// import { MinusCircleOutlined } from "@ant-design/icons";
import { Form, Button, InputNumber, Tabs } from "antd";
import { useState } from "react";
import Betline from "./Betline";
import merge from "lodash/merge";
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};
const WinSituations = ({ data, path, handlePatch }: any) => {
  const root = "environments";
  /* Add Form */
  const environments = data?.environments;
  const [activeEnvKey, setActiveEnvKey] = useState("");
  const [form] = Form.useForm();
  const [betlineConfigForClone, setBetlineConfigForClone] = useState();

  const onEnvChange = (selEnvTab: string) => {
    setActiveEnvKey(selEnvTab);
    const paths =
      data[root][selEnvTab][path] &&
      data[root][selEnvTab][path].length ===
        environments[selEnvTab]["numberOfBetLines"]
        ? data[root][selEnvTab][path]
        : [...new Array(environments[selEnvTab]["numberOfBetLines"])];

    form.setFieldsValue({ [path]: paths });
  };

  const onFinish = (envPane: string, values: any) => {
    data[root][envPane]["betLines"] = values["betLines"];
    handlePatch({ [root]: data[root] });
    // const patch = {
    //   [envPane]: {
    //     betLines: {
    //       // ...data[root][envPane]["betLines"],
    //       ...values["betLines"],
    //     },
    //   },
    // };
    // const mergedWithEnvironments = merge(data[root], patch);
    // handlePatch({ [root]: mergedWithEnvironments });
  };
  return (
    <Tabs
      hideAdd
      onChange={onEnvChange}
      activeKey={activeEnvKey}
      // type="editable-card"
      // onEdit={onEdit}
    >
      {environments &&
        Object.keys(environments).map((envPane) => (
          <TabPane tab={envPane} key={envPane}>
            <Form
              name={`winsituation_form${envPane}`}
              {...formItemLayoutWithOutLabel}
              onFinish={(values: any) => onFinish(envPane, values)}
              form={form}
              initialValues={{
                [path]:
                  data[root][envPane][path] &&
                  data[root][envPane][path].length ===
                    environments[envPane]["numberOfBetLines"]
                    ? data[root][envPane][path]
                    : [...new Array(environments[envPane]["numberOfBetLines"])],
                // betlines: [
                //   {
                //     number: 0,
                //     line: [1, 1, 1, 1, 1],
                //   },
                //   {
                //     number: 1,
                //     line: [1, 1, 1, 1, 1],
                //   },
                // ],
              }}
            >
              {environments[envPane]["wincalculator"] === "betlines" && (
                <>
                  <h3>
                    Bet Lines{" "}
                    {!betlineConfigForClone ? (
                      <Button
                        onClick={() => {
                          const vals = form.getFieldValue(path);
                          setBetlineConfigForClone(vals);
                        }}
                      >
                        Copy
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          onFinish(envPane,{ [path]: betlineConfigForClone });
                          form.setFieldsValue({ [path]: betlineConfigForClone });
                          setBetlineConfigForClone(undefined);
                        }}
                      >
                        Paste
                      </Button>
                    )}
                  </h3>

                  <Form.List
                    name={[path]}
                    rules={[
                      {
                        validator: async (_, names) => {
                          // if (!names || names.length < 2) {
                          //   return Promise.reject(new Error("At least 2 passengers"));
                          // }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            {...formItemLayout}
                            label={`Betline: ${index}:`}
                            required={false}
                            key={field.key}
                          >
                            <Form.Item name={[field.name, "number"]}>
                              <InputNumber
                                placeholder="number"
                                style={{ width: "60%" }}
                              />
                            </Form.Item>
                            <Betline
                              name={"line"}
                              field={field}
                              fieldPath={[path, field.name]}
                              form={form}
                            ></Betline>

                            {/* <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            /> */}
                          </Form.Item>
                        ))}
                        {/* <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "60%" }}
                    icon={<PlusOutlined />}
                  >
                    Add BetLine
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item> */}
                      </>
                    )}
                  </Form.List>
                </>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        ))}
    </Tabs>
  );
};

export default WinSituations;

// const Betline = ({ field }: any) => {
//   const [tags, setTags] = useState<Array<Tag>>(
//     form.getFieldValue([...fieldPath, field.name])
//       ? form
//           .getFieldValue([...fieldPath, field.name])
//           .map((tg: string, idx: number) => ({ id: "" + idx, text: tg }))
//       : []
//   );

//   return (
//     <Form.Item
//       name={[field.name, "line"]}
//       // key={[field.key, "number"]}
//       noStyle
//     >
//       <Input placeholder="multiplier" style={{ width: "60%" }} />
//     </Form.Item>
//   );
// };
