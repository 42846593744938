import { Tabs } from "antd";

// import {
//   ExclamationCircleOutlined,
//   MinusCircleOutlined,
//   PlusOutlined,
// } from "@ant-design/icons";
// import ReelStrip from "./ReelStrip";
import { useState } from "react";
import ReelSet from "./ReelSet";
import { presetReelSets } from "../../utilities/reelsLinking";
import { parseClubbedSymbols } from "../../utilities/colossalSymbls";
import Loader from "../../utils/Loader";
import merge from "lodash/merge";

const { TabPane } = Tabs;

const ReelsetsForm = ({ data, path, handlePatch }: any) => {
  const root = "environments";
  /* Add Form */
  const environments = data?.environments;

  const [activeEnvKey, setActiveEnvKey] = useState("");

  const symbolsSuggestions: Array<{
    id: string;
    text: string;
  }> = [...data.availableSymbols, ...parseClubbedSymbols(data)].map(
    (sym: string) => ({
      id: sym,
      text: sym,
    })
  );

  
  const [activeKey, setActiveKey] = useState("");


  const onChange = (selTab: string) => {
    setActiveKey(selTab);
  };
  const onEnvChange = (selEnvTab: string) => {
    setActiveEnvKey(selEnvTab);
    onChange("");
  };
  
  const getReelsets = (env: string) => {
    // ["basic", "freespin", "respin"]
    const reelsetsToBeSet = presetReelSets({
      ...data[root][env]
    });
    return reelsetsToBeSet;
  };
 
  const handleOnSave = (envPane: string, reelset: string, formData: any) => {
    const patch = {
      [envPane]: {
        reelsets: { ...data[root][envPane]["reelsets"], [reelset]: formData },
      },
    };
    const mergedWithEnvironments = merge(data[root], patch);
    handlePatch({ [root]: mergedWithEnvironments }); // to make sure that it will only patch root (environment)
  };
  const [rsConfigForClone, setRsConfigForClone] = useState();
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(false);

  return (
    <div>
      <Tabs
        hideAdd
        onChange={onEnvChange}
        activeKey={activeEnvKey}
        // type="editable-card"
        // onEdit={onEdit}
      >
        {environments &&
          Object.keys(environments).map((envPane) => (
            <TabPane tab={envPane} key={envPane}>
              <Tabs
                hideAdd
                onChange={onChange}
                activeKey={activeKey}
                // type="editable-card"
                // onEdit={onEdit}
              >

                {getReelsets(envPane).map((pane) => (
                  <TabPane tab={pane} key={pane}>
                    {!loaderVisibility ? (
                      <ReelSet
                        reelset={pane}
                        symbolsSuggestions={symbolsSuggestions}
                        values={
                          data &&
                          data[root] &&
                          data[root][envPane] &&
                          data[root][envPane]["reelsets"] &&
                          data[root][envPane]["reelsets"][pane]
                            ? data[root][envPane]["reelsets"][pane]
                            : {}
                        }
                        handleOnSave={(values: any) =>
                          handleOnSave(envPane, pane, values)
                        }
                        numberOfReels={data[root][envPane]["numberOfReels"]}
                        copiedVals={rsConfigForClone}
                        onPaste={() => {
                          setRsConfigForClone(undefined);
                          setLoaderVisibility(true);
                        }}
                        onCopy={(vals: any) => {
                          setRsConfigForClone(vals);
                        }}
                        availableReelsets= {getReelsets(envPane)}
                      />
                    ) : (
                      <Loader
                        onDone={() => {
                          setLoaderVisibility(false);
                        }}
                      ></Loader>
                    )}
                  </TabPane>
                ))}
              </Tabs>
            </TabPane>
          ))}
      </Tabs>
    </div>
  );
};
export default ReelsetsForm;
