import { Form, Input, Button, Modal } from "antd";
import {
  ExclamationCircleOutlined,
  // MinusCircleOutlined,
  // PlusOutlined,
} from "@ant-design/icons";
// import { useState } from "react";
import TagInput from "./TagInput";
import CollectibleConfigurationPanel from "../components/CollectibleConfigurationPanel";
// import TagInputForChild from "./TagInputForChild";
// import EnvironmentInfoForm from "../environmentInfo";
// import SymbolBehaviourConfigurator from "../environmentInfo/SymbolBehaviourConfigurator";

// const formItemLayout = {
//   labelCol: {
//     xs: { span: 24 },
//     sm: { span: 4 },
//   },
//   wrapperCol: {
//     xs: { span: 24 },
//     sm: { span: 20 },
//   },
// };
// const formItemLayoutWithOutLabel = {
//   wrapperCol: {
//     xs: { span: 24, offset: 0 },
//     sm: { span: 20, offset: 4 },
//   },
// };

const BasicInfoForm = ({ data, handleCreate, handlePatch }: any) => {
  // const [winType, setWinType] = useState<string>(
  //   data ? data.wincalculator : ""
  // );
  // const [reelLinking, setReelLinking] = useState<string>(
  //   data ? data.reelLinking : ""
  // );

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Success:", values);
    handleCreate(values);
  };
  const patchExisting = () => {
    const formVsalue = form.getFieldsValue();
    console.log(formVsalue);
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <h3>Are you sure you want to update?</h3>
          <p>
            Do note that this might be a breaking change for rest of the
            configuration!
          </p>
          <p>
            Please revist all other tabs to ensure that your update in
            compatible
          </p>
        </div>
      ),

      okText: "Confirm And Update",
      cancelText: "Cancel",
      onOk() {
        const formValue = form.getFieldsValue();
        handlePatch({ ...formValue });
      },
    });
  };
  // const onFinishFailed = (errorInfo: any) => {
  //   console.log("Failed:", errorInfo);
  // };
  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={data}
      onFinish={onFinish}
      //  onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="clientId"
        name="clientId"
        rules={[{ required: true, message: "Please input clientId!" }]}
      >
        <Input />
      </Form.Item>

      <TagInput
        name="availableSymbols"
        form={form}
        label="Available Symbols"
        rules={[{ required: true, message: "Please input symbols!" }]}
        help={
          "All symbols including wild and scatter eg: sym0,sym1,sym3,sym4,sym5,sym6,sym7,sym8,sym9,sym10"
        }
      ></TagInput>
      <TagInput
        name="clubbedSymbols"
        form={form}
        label="clubbedSymbols"
      ></TagInput>

      <TagInput
        name="scattersymbols"
        form={form}
        label="scattersymbols"
        rules={[{ required: true, message: "Please input symbols!" }]}
        help={"Subset of symbols"}
      ></TagInput>

      <CollectibleConfigurationPanel
        name="collectibles"
        form={form}
        label="Collectibles"
      />

      {/* <Form.Item name={"reelsAdjustmentMode"} label="reelsAdjustmentMode">
        <Select>
          <Select.Option value="">None</Select.Option>
          <Select.Option value="burst-move-down-symbol-by-symbol">
            Burst And Move Downward
          </Select.Option>
          <Select.Option value="burst-move-up-symbol-by-symbol">
            Burst And Move Upward
          </Select.Option>
          <Select.Option value="burst-move-symbol-by-symbol-to-maximize-colossal">
            Burst And Move To Maximize Colossal
          </Select.Option>
          <Select.Option value="burst-move-down-all" disabled>
            Burst And Move Downward
          </Select.Option>
          <Select.Option value="burst-move-up-all" disabled>
            Burst And Move Upward
          </Select.Option>
          <Select.Option value="burst-move-all-to-maximize-colossal" disabled>
            Burst And Move To Maximize Colossal
          </Select.Option>
        </Select>
      </Form.Item> */}

      {/* <Form.Item
        name="dynamicReelLinking"
        valuePropName="checked"
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Checkbox>Dynamic Reel Linking</Checkbox>
      </Form.Item> */}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Create New
        </Button>
        {data?.configid && (
          <Button type="default" onClick={patchExisting}>
            Update
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default BasicInfoForm;
