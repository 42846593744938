import {
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Modal,
  Tabs,
  Checkbox,
} from "antd";
import {
  ExclamationCircleOutlined,
  // MinusCircleOutlined,
  // PlusOutlined,
} from "@ant-design/icons";
import { useState } from "react";
// import TagInput from "../basicInfo/TagInput";
// import TagInputForChild from "../basicInfo/TagInputForChild";
import SymbolBehaviourConfigurator from "./SymbolBehaviourConfigurator";
import ReelsLinkingConfigurator from "./ReelsLinkingConfigurator";
import merge from "lodash/merge";
import { pick } from "lodash";
import TagInput from "../basicInfo/TagInput";
// import { presetReelSets } from "../../utilities/reelsLinking";
const { TabPane } = Tabs;

// const formItemLayout = {
//   labelCol: {
//     xs: { span: 24 },
//     sm: { span: 4 },
//   },
//   wrapperCol: {
//     xs: { span: 24 },
//     sm: { span: 20 },
//   },
// };
// const formItemLayoutWithOutLabel = {
//   wrapperCol: {
//     xs: { span: 24, offset: 0 },
//     sm: { span: 20, offset: 4 },
//   },
// };

const EnvironmentInfoForm = ({ data, handleCreate, handlePatch }: any) => {
  const root = "environments";
  /* Add Form */
  const [environments, setEnvironments] = useState<any>(data?.environments);
  const [newEnvironmentName, setNewEnvironmentName] = useState("");
  const add = () => {
    if (newEnvironmentName.trim().length > 2) {
      setEnvironments({ ...environments, [newEnvironmentName.trim()]: {} });
      setNewEnvironmentName("");
    }
  };

  /* Tabs */
  const [activeKey, setActiveKey] = useState("");
  const onChange = (selTab: string) => {
    setActiveKey(selTab);
  };

  const onEdit = (targetKey: any, action: "add" | "remove") => {
    if (action === "remove") {
      Modal.confirm({
        title: "Confirm",
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure you want to delete this Environment?",
        okText: "Confirm And save",
        cancelText: "Cancel",
        onOk() {
          environments[targetKey] = undefined;
          delete environments[targetKey];
          const patch = { [root]: { ...data[root], [targetKey]: undefined } };
          handlePatch(patch);
          setEnvironments({ ...environments });
          if (environments.length > 0) {
            // setActiveKey(updatedEnvironment[0]);
            setActiveKey("");
          } else {
            setActiveKey("");
          }
        },
      });
    }
  };

  // const [winType, setWinType] = useState<string>(
  //   data ? data.wincalculator : ""
  // );
  // const [reelLinking, setReelLinking] = useState<string>(
  //   data ? data.reelLinking : ""
  // );

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Success:", values);
    const updates: any = {};
    const updatedEnvironments = Object.keys(values[root]);
    for (let idx = 0; idx < updatedEnvironments.length; idx++) {
      const envToUpdate = updatedEnvironments[idx];

      const mergedWithEnvironments = merge(
        values[root],
        pick(data[root], [`${envToUpdate}.reelsets`, `${envToUpdate}.betLines`])
      );

      updates[envToUpdate] = mergedWithEnvironments[envToUpdate];
    }
    handlePatch({ [root]: { ...data[root], ...updates } }); // to make sure that it will only patch root (environment)
  };
  // const patchExisting = () => {
  //   Modal.confirm({
  //     title: "Confirm",
  //     icon: <ExclamationCircleOutlined />,
  //     content: (
  //       <div>
  //         <h3>Are you sure you want to update?</h3>
  //         <p>
  //           Do note that this might be a breaking change for rest of the
  //           configuration!
  //         </p>
  //         <p>
  //           Please revist all other tabs to ensure that your update in
  //           compatible
  //         </p>
  //       </div>
  //     ),

  //     okText: "Confirm And Update",
  //     cancelText: "Cancel",
  //     onOk() {
  //       const formValue = form.getFieldsValue();
  //       handlePatch({ ...formValue });
  //     },
  //   });
  // };
  // const onFinishFailed = (errorInfo: any) => {
  //   console.log("Failed:", errorInfo);
  // };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Input
          value={newEnvironmentName}
          onChange={(e: any) => {
            setNewEnvironmentName(e.target.value);
          }}
        ></Input>
        <Button onClick={add}>ADD</Button>
      </div>
      <Tabs
        hideAdd
        onChange={onChange}
        activeKey={activeKey}
        type="editable-card"
        onEdit={onEdit}
      >
        {environments &&
          Object.keys(environments).map((pane) => (
            <TabPane tab={pane} key={pane}>
              <Form
                form={form}
                name={pane}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={data}
                onFinish={onFinish}
                //  onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="numberOfReels"
                  name={[root, pane, "numberOfReels"]}
                  rules={[
                    { required: true, message: "Please input numberOfReels!" },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  label="symbolsPerReel"
                  name={[root, pane, "symbolsPerReel"]}
                  rules={[
                    { required: true, message: "Please input symbolsPerReel!" },
                  ]}
                >
                  <InputNumber />
                </Form.Item>

                <Form.Item
                  label="defaultReelset"
                  name={[root, pane, "defaultReelset"]}
                  rules={[
                    { required: true, message: "Please input defaultReelset!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="spinType"
                  name={[root, pane, "spinType"]}
                  rules={[
                    { required: true, message: "Please input spinType!" },
                  ]}
                >
                  <Select>
                    <Select.Option value="spin">Spin</Select.Option>
                    <Select.Option value="avalanche">Avalanche</Select.Option>
                    <Select.Option value="cascadded" disabled>
                      Cascadded
                    </Select.Option>
                    <Select.Option value="helix" disabled>
                      Helix
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    // const oldVal = get(prevValues, name);
                    // const newVal = get(curValues, name);
                    // if (newVal && oldVal !== newVal) {
                    //   return true;
                    // }
                    return true;
                  }}
                >
                  {() => {
                    return (
                      <>
                        <Form.Item
                          label="wincalculator"
                          name={[root, pane, "wincalculator"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input wincalculator!",
                            },
                          ]}
                        >
                          <Select>
                            <Select.Option value="betlines">
                              Betlines
                            </Select.Option>
                            <Select.Option value="betways">
                              Betways
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        {form.getFieldValue([root, pane, "wincalculator"]) ===
                          "betlines" && (
                          <>
                            <Form.Item
                              label="numberOfBetLines"
                              name={[root, pane, "numberOfBetLines"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input numberOfBetLines!",
                                },
                              ]}
                            >
                              <InputNumber />
                            </Form.Item>
                            <Form.Item
                              label="distributeBetOnBetLines"
                              name={[root, pane, "distributeBetOnBetLines"]}
                              valuePropName="checked"
                              help={
                                "This enables the bet placed by player will be divided by number of betlines and the win computation of symbol payouts being done on the splitted amount per betline"
                              }
                            >
                              <Checkbox></Checkbox>
                            </Form.Item>
                          </>
                        )}
                      </>
                    );
                  }}
                </Form.Item>

                {/* <TagInput
                  form={form}
                  label="gameModes"
                  // name="gameModes"
                  name={[root, pane, "gameModes"]}
                  // rules={[{ required: true, message: "Please input gameModes!" }]}
                ></TagInput> */}

                <SymbolBehaviourConfigurator
                  form={form}
                  name={[root, pane, "wildSymbols"]}
                  configurationName={[root, pane, "wildBehaviour"]}
                ></SymbolBehaviourConfigurator>
                {/* 
                <TagInput
                  name="burstableSymbols"
                  form={form}
                  label="burstableSymbols"
                  help={"Subset of symbols"}
                ></TagInput> */}
                <ReelsLinkingConfigurator
                  form={form}
                  name={[root, pane, "reelLinking"]}
                  connectedReelsName={[root, pane, "connectedReels"]}
                  availableLinkableReels={[
                    root,
                    pane,
                    "availableLinkableReels",
                  ]}
                />

                <TagInput
                  name={[root, pane, "collectibleSymbols"]}
                  form={form}
                  label="Collectible Symbols"
                ></TagInput>

                <Form.Item name={[root, pane, "bonusGame"]} label="bonusGame">
                  <Select>
                    <Select.Option value="">None</Select.Option>
                    <Select.Option value="pickandchooseitems">
                      Pick and Choose Items
                    </Select.Option>
                    <Select.Option value="snakesandladders">
                      Snakes And Ladders
                    </Select.Option>
                  </Select>
                </Form.Item>

                {/* <Form.Item
                  label="Additional Features"
                  name={[root, pane, "additionalFeatures"]}
                  rules={[
                    // { required: true, message: "Please input keys of additional Features!" },
                  ]}
                >
                  <Input />
                </Form.Item> */}
                <TagInput
                  name={[root, pane, "additionalFeatures"]}
                  form={form}
                  label="Additional Features"
                  // rules={[{ required: true, message: "Please input symbols!" }]}
                  help={""}
                  // onUpdate={() => {
                  //   // console.log(form?.getFieldValue(name));
                  // }}
                ></TagInput>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                  {/* {data?.configid && (
                    <Button type="default" onClick={patchExisting}>
                      Update
                    </Button>
                  )} */}
                </Form.Item>
              </Form>
            </TabPane>
          ))}
      </Tabs>
    </div>
  );
};

export default EnvironmentInfoForm;
