import { Button, Collapse, Modal, Space } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  GAME_SERVER_CONFIG,
  SIMULATION_GAME_SERVER_CONFIG,
} from "../utils/Constants";
const { Panel } = Collapse;

export const GameSimlator = ({ configId, operatorId }: any) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [simulations, setSimulations] = useState([]);

  const [gameFeaturesUsage, setGameFeaturesUsage] = useState<any>(undefined);
  //   useEffect(() => {
  //     const fetchStats = async () => {
  //       const statsUrl = `https://wallet-and-bonus-47kby.ondigitalocean.app/api/tenant/923d95cb-3be0-41a4-997b-a41d2d657467/gameolive/get-game-feature-hit-ratio?filter[productId]=${configId}`;
  //       const stats = await axios.get(statsUrl);
  //       const gameStats = stats.data;
  //       if (gameStats && gameStats.rows && gameStats.rows.length === 1) {
  //         setGameFeaturesUsage(gameStats.rows[0]);
  //       }
  //     };
  //     fetchStats();
  //   }, [configId]);
  const loadSimulations = async () => {
    const statsUrl = `${SIMULATION_GAME_SERVER_CONFIG.server}/api/simulations/get?filter[configurationId]=${configId}`;
    const stats = await axios.get(statsUrl);
    const gameSimluations = stats.data;
    setSimulations(gameSimluations);
    showModal();
  };
  const loadStats = async (simulationConfigId: string) => {
    const statsUrl = `${SIMULATION_GAME_SERVER_CONFIG.server}/api/simulations/get-results?filter[productId]=${simulationConfigId}`;
    const stats = await axios.get(statsUrl);
    const gameStats = stats.data;
    if (gameStats && gameStats.rows && gameStats.rows.length === 1) {
      setGameFeaturesUsage(gameStats.rows[0]);
    }
  };

  const simulate = async (numberOfRounds: number) => {
    var config = {
      method: "get",
      url: `${GAME_SERVER_CONFIG.server}/api/get-config/${GAME_SERVER_CONFIG.operatorId}/${configId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    //@ts-ignore
    const resp = await axios(config);
    if (resp.data && resp.data.configuration) {
      var data = {
        // gameid: "slot-linked-server",
        ...resp.data.configuration,
        configurator: "v0.0.2",
      };
      var uploadConfig = {
        method: "post",
        url: `${SIMULATION_GAME_SERVER_CONFIG.server}/api/simulations/set-config`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          gameConfiguration: data,
          simulationConfiguration: {
            numberOfRounds: numberOfRounds,
            betAmount: 1,
          },
        },
      };
      //@ts-ignore
      const update = await axios(uploadConfig);
      console.log(update);
      //SIMULATION_GAME_SERVER_CONFIG
    }
  };

  const showModal = () => {
    setDialogVisible(true);
  };

  const handleOk = () => {
    setDialogVisible(false);
  };

  const handleCancel = () => {
    setDialogVisible(false);
  };
  return (
    <>
      <div>
        <Button onClick={() => simulate(1000)}>Simulate</Button>
        {!gameFeaturesUsage && (
          <Button onClick={loadSimulations} type="link">
            Show Simulation Result
          </Button>
        )}
        <table>
          {gameFeaturesUsage &&
            Object.keys(gameFeaturesUsage).map((stat) => (
              <tr>
                <td>{stat}:</td>
                <td>{gameFeaturesUsage[stat]}</td>
              </tr>
            ))}
        </table>
      </div>
      <Modal
        title={configId}
        visible={dialogVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <table width="100%">
          {simulations
            ?.sort((a: any, b: any) => b.createdOn - a.createdOn)
            .map((sim: any) => (
              <>
                <tr>
                  <td colSpan={2} style={{ background: "#ccc" }}>
                    {moment(sim.createdOn).format("YYYY-MM-DD HH:mm")}
                  </td>
                </tr>
                {sim.walletStats && (
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        background: "rgb(230, 247, 255)",
                        textAlign: "center",
                      }}
                    >
                      Wallet Stats
                    </td>
                  </tr>
                )}
                {sim.walletStats &&
                  Object.keys(sim.walletStats).map((stat) => (
                    <tr style={{ border: "1px solid #ccc" }}>
                      <td>{stat}:</td>
                      <td>{sim.walletStats[stat]}</td>
                    </tr>
                  ))}
                {sim.gameServerStats && sim.gameServerStats.betlinesHits && (
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        background: "rgb(230, 247, 255)",
                        textAlign: "center",
                      }}
                    >
                      Game Server: BetLine Stats
                    </td>
                  </tr>
                )}
                {sim.gameServerStats.betlinesHits &&
                  sim.gameServerStats.betlinesHits
                    .sort((a: any, b: any) => a._id.betline - b._id.betline)
                    .map((blHit: any) => (
                      <tr style={{ border: "1px solid #ccc" }}>
                        <td>
                          {blHit._id.betline} ({blHit._id.mode}):
                        </td>
                        <td>{blHit.count}</td>
                      </tr>
                    ))}
                {sim.gameServerStats && sim.gameServerStats.symbolHits && (
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        background: "rgb(230, 247, 255)",
                        textAlign: "center",
                      }}
                    >
                      Game Server: Symbol Stats
                    </td>
                  </tr>
                )}
                {sim.gameServerStats.symbolHits &&
                  sim.gameServerStats.symbolHits
                    .sort(
                      (a: any, b: any) =>
                        a._id.winningSymbol - b._id.winningSymbol
                    )
                    .map((symHit: any) => (
                      <tr style={{ border: "1px solid #ccc" }}>
                        <td>
                          {symHit._id.winningSymbol} ({symHit._id.mode}):
                        </td>
                        <td>{symHit.count}</td>
                      </tr>
                    ))}
              </>
            ))}
        </table>
      </Modal>
    </>
  );
};
